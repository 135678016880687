import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import MenuToggle from './MenuToggle'
import NavMenu from './NavMenu'

const HamburgerMenuContainer = styled.div`
  display: flex;
`

const MenuContainer = styled(motion.div)`
  min-width: 300px;
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(246, 44, 132, 1) 0%,
    rgba(133, 0, 226, 1) 100%
  );
  box-shadow: -2px 0 2px rgba(15, 15, 15, 0.3);
  z-index: 90;
  display: flex;
  align-items: center;
  position: fixed;
  transform: translateX(4em);
  top: 0;
  right: 0;
  user-select: none;
  padding: 1em 2.5em;
`

const menuVariants = {
  open: { transform: 'translateX(0%)' },
  closed: { transform: 'translateX(100%)' },
}

const menuTransition = {
  type: 'tween',
  duration: 1,
  stiffness: 33,
  delay: 0.1,
}

const HamburgerMenu = (props) => {
  const [isOpen, setOpen] = useState(false)

  const toggleMenu = () => {
    setOpen(!isOpen)
    console.log(isOpen)
  }

  return (
    <HamburgerMenuContainer>
      <MenuToggle toggle={toggleMenu} isOpen={isOpen} />
      <MenuContainer
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        variants={menuVariants}
        transition={menuTransition}
      >
        <Container className='px-0'>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <NavMenu isOpen={isOpen} />
            </Col>
          </Row>
        </Container>
      </MenuContainer>
    </HamburgerMenuContainer>
  )
}

export default HamburgerMenu
