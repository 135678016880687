import React, { useEffect, useRef, useState } from 'react'
import { Container, Image, Nav, Navbar } from 'react-bootstrap'
import { matchPath, useLocation } from 'react-router'
import { LinkContainer } from 'react-router-bootstrap'
import Logo from '../../images/Media-Express-Logo.png'
import HamburgerMenu from './HamburgerMenu'
import './Nav.css'

const Header = () => {
  const path = useLocation()
  const match = matchPath('/', {
    path: '/',
    exact: true,
    strict: true,
  })
  const [navBackground, setNavBackground] = useState(false)
  const navRef = useRef()
  navRef.current = navBackground
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50
      if (navRef.current !== show) {
        setNavBackground(show)
      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <Navbar
      style={
        path.pathname === match.path
          ? {
              transition: '1s ease',
              background: navBackground ? '#1A012D' : 'transparent',
            }
          : { background: '#1A012D' }
      }
      fixed={path.pathname === match.path ? 'top' : false}
    >
      <Container>
        <LinkContainer to='/'>
          <Navbar.Brand>
            <Image src={Logo} className='w-100' />
          </Navbar.Brand>
        </LinkContainer>
        <Nav className='ml-auto d-flex align-items-center'>
          <LinkContainer to='/contact' className='pr-5'>
            <Nav.Link>CONTACT US</Nav.Link>
          </LinkContainer>
          <HamburgerMenu />
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Header
