import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Service1 from '../images/service/service1.png'
import Service2 from '../images/service/service2.png'
import Service3 from '../images/service/service3.png'
import Button from '../utils/Button'

const ServicesScreen = () => {
  return (
    <Container
      className='services margin-top margin-bottom px-0'
      fluid
      style={{ position: 'relative' }}
    >
      <Row>
        <Col lg={6} md={6} xm={12} sm={12}>
          <Image
            src={Service1}
            fluid
            className='about-image-home'
            width='100%'
          />
        </Col>
        <Col
          lg={6}
          md={6}
          xm={12}
          sm={12}
          className='pl-5 d-flex align-items-center'
        >
          <Container className='d-flex justify-content-center'>
            <Row style={{ position: 'relative' }} className='service-text'>
              <Col lg={12} md={12} xm={12} sm={12}>
                <div className='wrapper'>
                  <h2 className='yellow'>Creative</h2>
                  <p className='yellow'>Services</p>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div>
                  <p>
                    We create new brands and revitalize existing ones. We make
                    sure to dig deep in the essence of your brand to help you
                    stir it and convey it in a unique and memorable way.
                  </p>
                  <p className='pt-3' style={{ textTransform: 'uppercase' }}>
                    Our creative services include
                  </p>
                  <ul className='yellow'>
                    <li>Branding</li>
                    <li>Design</li>
                    <li>Advertising</li>
                    <li>Media planning</li>
                  </ul>
                  <p>
                    Fill out the brand questionnaire below to see how we can
                    help improve your brand
                  </p>
                  <Button
                    path='/projects'
                    color='#ffae46'
                    fontColor='white'
                    text='Browse Projects'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col
          lg={6}
          md={6}
          xm={12}
          sm={12}
          className='d-flex align-items-center'
        >
          <Container className='d-flex justify-content-center'>
            <Row style={{ position: 'relative' }} className='service-text'>
              <Col lg={12} md={12} xm={12} sm={12}>
                <div className='wrapper'>
                  <h2 className='pink'>Social Media</h2>
                  <p className='pink'>Services</p>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div>
                  <p>
                    Let’s get your brand buzzing! We connect people to brands.
                    We make sure you reach the right audience through strategic
                    planning and campaigns that stand out.
                  </p>
                  <p className='pt-3' style={{ textTransform: 'uppercase' }}>
                    Our creative services include
                  </p>
                  <ul className='pink'>
                    <li>Account Management</li>
                    <li>STRATEGIES</li>
                    <li>CONTENT CREATION</li>
                    <li>ADS & Promoted Content</li>
                    <li>Media Planning</li>
                    <li>Copywriting</li>
                  </ul>
                  <p>
                    Fill out the brand questionnaire below to see how we can
                    help improve your brand
                  </p>
                  <Button
                    path='/projects'
                    color='#f62c84'
                    fontColor='white'
                    text='Browse Projects'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col lg={6} md={6} xm={12} sm={12}>
          <Image
            src={Service2}
            className='about-image-home'
            style={{ float: 'right' }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={6} xm={12} sm={12}>
          <Image
            src={Service3}
            fluid
            className='about-image-home'
            width='100%'
          />
        </Col>
        <Col
          lg={6}
          md={6}
          xm={12}
          sm={12}
          className='pl-5 d-flex align-items-center'
        >
          <Container className='d-flex justify-content-center'>
            <Row style={{ position: 'relative' }} className='service-text'>
              <Col lg={12} md={12} xm={12} sm={12}>
                <div className='wrapper'>
                  <h2 className='blue'>Digital Solutions</h2>
                  <p className='blue'>Services</p>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div>
                  <p>
                    Let’s get your brand buzzing! We connect people to brands.
                    We make sure you reach the right audience through strategic
                    planning and campaigns that stand out.
                  </p>
                  <p className='pt-3' style={{ textTransform: 'uppercase' }}>
                    Our mocial media services include
                  </p>
                  <ul className='blue'>
                    <li>Account Management</li>
                    <li>STRATEGIES</li>
                    <li>CONTENT CREATION</li>
                    <li>ADS & Promoted Content</li>
                    <li>Media Planning</li>
                    <li>Copywriting</li>
                  </ul>
                  <p>
                    Fill out the brand questionnaire below to see how we can
                    help improve your brand
                  </p>
                  <Button
                    path='/projects'
                    color='#0071bc'
                    fontColor='white'
                    text='Browse Projects'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default ServicesScreen
