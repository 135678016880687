import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import CarouselHome from '../components/CarouselHome'
import OurWork from '../images/landing-page/Phone.png'
import { motion } from 'framer-motion'
import AboutHome from '../components/home/AboutHome'
import Button from '../utils/Button'

const HomeScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: '-100vw',
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: '100vw',
      scale: 1.2,
    },
  }
  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }

  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <Container fluid className='px-0'>
        <CarouselHome />
      </Container>
      <AboutHome />
      <Container className='margin-bottom '>
        <Row>
          <Col lg={7} md={7} sm={12} xs={12}>
            <h1 className='purple py-5'>our work</h1>
            <p>
              <strong>The Client</strong>
              <br /> Cedar Bakery.
            </p>
            <p>
              <strong>The Challenge</strong>
              <br /> Increase engagement on Instagram and reach the local
              community.
            </p>
            <p>
              <strong>What We Did</strong>
              <br /> We created a unique style and focused on targeting the
              local communities with more engaging and product oriented posts.
            </p>
            <p>
              <strong>The Result</strong>
              <br /> Page visits increase of 180% and still growing. More visits
              from residents to the shop which led directly to increase in
              sales.
            </p>
            <Button
              path='/projects'
              color='#8500e2'
              text='Learn More'
              fontColor='white'
            />
          </Col>
          <Col
            lg={5}
            md={5}
            sm={12}
            xs={12}
            className='px-0 d-flex align-items-center'
          >
            <Image src={OurWork} className='w-100' />
          </Col>
        </Row>
      </Container>
    </motion.div>
  )
}

export default HomeScreen
