import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Details1 from '../images/details/details-1.png'
import Group1 from '../images/details/group-1.png'
import Group2 from '../images/details/group-2.png'
import Group3 from '../images/details/group-3.png'
import Group4 from '../images/details/group-4.png'
import Group5 from '../images/details/group-6.png'
import Group6 from '../images/details/group-5.png'
import Slide1 from '../images/details/slider/1.png'
import Slide2 from '../images/details/slider/2.png'
import Slide3 from '../images/details/slider/3.png'
import Slide4 from '../images/details/slider/4.png'
import Slide5 from '../images/details/slider/5.png'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import { motion } from 'framer-motion'

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const DetailsScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: '-100vw',
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: '100vw',
      scale: 1.2,
    },
  }
  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <Container fluid className='margin-left pr-0 mt-5'>
        <Row>
          <Col
            lg={4}
            md={4}
            sm={12}
            xs={12}
            className='d-flex align-items-center'
          >
            <div>
              <h3>JUTHOOR</h3>
              <Row>
                <Col lg={4} md={4} sm={4} xs={4}>
                  <div className='balloons'>
                    <p>Logo</p>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4}>
                  <div className='balloons'>
                    <p>branding</p>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4}>
                  <div className='balloons'>
                    <p>Social Media</p>
                  </div>
                </Col>
              </Row>
              <p>Juthoor is a spa for body and mind wellbeing</p>
              <p>
                <strong>What We Did</strong>
                <br /> We created a unique style and focused on targeting the
                local communities with more engaging and product oriented posts
              </p>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={12}>
            <Image src={Details1} className='w-100' />
          </Col>
        </Row>
        <div className='the-logo my-5'>
          <h3>The Logo</h3>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <Image src={Group1} className='w-100' />
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <Image src={Group2} className='w-100' />
                </Col>
              </Row>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className='d-flex align-items-center'
            >
              <p>
                <strong>What We Did</strong>
                <br /> We created a unique style and focused on targeting the
                local communities with more engaging and product oriented posts
              </p>
            </Col>
          </Row>
        </div>
        <div className='the-logo my-5'>
          <h3>Stationery</h3>
          <Row>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Image src={Group3} className='w-100 h-100' />
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Image src={Group4} className='w-100 h-100' />
            </Col>
          </Row>
        </div>
        <div className='the-logo mt-5'>
          <h3>MERCHANDISE</h3>
        </div>
      </Container>
      <Container fluid className='px-0'>
        <Swiper
          spaceBetween={50}
          slidesPerView={3}
          scrollbar={{ draggable: true, hide: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          <SwiperSlide>
            <Image src={Slide1} className='w-100' />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={Slide2} className='w-100' />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={Slide3} className='w-100' />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={Slide4} className='w-100' />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={Slide5} className='w-100' />
          </SwiperSlide>
        </Swiper>
      </Container>
      <Container fluid className='margin-left pr-0 mt-5'>
        <div className='the-logo mt-5'>
          <h3>Social Media</h3>
        </div>
      </Container>
      <Container fluid className='margin-right pl-0 mb-5'>
        <Row>
          <Col lg={8} md={8} sm={12} xs={12}>
            <Image src={Group5} className='w-100 h-100' />
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <Image src={Group6} className='w-100 h-100' />
          </Col>
        </Row>
      </Container>
    </motion.div>
  )
}

export default DetailsScreen
