import { motion } from 'framer-motion'
import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import './Button.css'

const Button = ({ color, path, text, width, fontColor }) => {
  return (
    <LinkContainer to={path}>
      <motion.button
        initial={{
          backgroundColor: `${color}`,
          borderColor: `${color}`,
          width: `${width}`,
          color: `${fontColor}`,
        }}
        whileHover={{
          scale: 1.1,
        }}
        whileTap={{ scale: 0.8 }}
        transition={{ duration: 0.1 }}
        exit={{ color: `${fontColor}` }}
      >
        {text}
      </motion.button>
    </LinkContainer>
  )
}

export default Button
