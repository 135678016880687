import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import AboutImage from '../../images/landing-page/Balloon.png'
import './Home.css'

const AboutHome = () => {
  return (
    <Container
      fluid
      className='margin-top margin-bottom px-0'
      style={{ position: 'relative' }}
    >
      <Row>
        <Col lg={4} md={4} xm={12} sm={12}>
          <Image src={AboutImage} className='about-image-home' />
        </Col>
        <Col lg={7} md={7} xm={12} sm={12}>
          <Container>
            <Row className='py-5' style={{ position: 'relative' }}>
              <Col lg={12} md={12} xm={12} sm={12}>
                <h1 className='pink'>About us</h1>
                <h4 className='pt-5'>
                  We are a creative agency specialized in advertising and
                  digital creation
                  <br /> working with companies that dare to be different!
                </h4>
              </Col>
            </Row>
            <Row className='py-5'>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  <h5>Mission</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  <h5>Vision</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default AboutHome
