import { motion } from 'framer-motion'
import React from 'react'
import { Carousel, Image } from 'react-bootstrap'
import Image1 from '../images/contact/qatar.png'
import Image2 from '../images/contact/leb.png'
import Button from '../utils/Button'

const CarouselContact = () => {
  return (
    <Carousel style={{ height: '91.3vh', width: '100%' }} fade>
      <Carousel.Item>
        <Image src={Image1} width='100%' style={{ height: '91.3vh' }} />
        <Carousel.Caption>
          <h2>Qatar</h2>
          <p>Level 15, Commercial Bank Tower, West Bay, Doha, Qatar. P.O.BOX</p>
          <p>+974 50006456</p>
          <p>info@mediaexpress.agency</p>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, ease: 'easeIn', delay: 1 }}
          >
            <Button
              path='/contactform'
              color='#FFAE46'
              text='Get in Touch'
              fontColor='white'
            />
          </motion.div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Image src={Image2} width='100%' style={{ height: '91.3vh' }} />
        <Carousel.Caption>
          <h2>Lebanon</h2>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, ease: 'easeIn', delay: 1 }}
          >
            <p>Rabieh main road, Lebanon, Antelias</p>
            <p>+961 3159127</p>
            <p>info@mediaexpress.agency</p>
            <Button
              path='/contactform'
              color='#FFAE46'
              text='Get in Touch'
              fontColor='white'
            />
          </motion.div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}

export default CarouselContact
