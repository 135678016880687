import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const NavMenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const NavList = styled.ul`
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const NavLink = styled(motion.li)`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  a {
    text-decoration: none;
    color: white;
    transition: all 200ms ease-in-out;
    font-family: 'Large-Black';
    font-size: 5rem;
    text-transform: uppercase;
    &:hover {
      transform: scale(1.1);
    }
  }
`

const variants = {
  show: { transfrom: 'translateX(0em)', opacity: 1 },
  hide: { transfrom: 'translateX(5em)', opacity: 0 },
}

const NavMenu = ({ isOpen }) => {
  return (
    <NavMenuContainer>
      <NavList>
        <NavLink
          initial={false}
          animate={isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.3, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.05, duration: 0.05 },
            },
          }}
          onClick={isOpen ? true : false}
        >
          <Link to='/'>home</Link>
        </NavLink>
        <NavLink
          initial={false}
          animate={isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.4, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.1, duration: 0.05 },
            },
          }}
          onClick={isOpen ? true : false}
        >
          <Link to='/services'>services</Link>
        </NavLink>
        <NavLink
          initial={false}
          animate={isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.5, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.15, duration: 0.05 },
            },
          }}
          onClick={isOpen ? true : false}
        >
          <Link to='/projects'>projects</Link>
        </NavLink>
        <NavLink
          initial={false}
          animate={isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.6, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.2, duration: 0.05 },
            },
          }}
          onClick={isOpen ? true : false}
        >
          <Link to='/contact'>contact</Link>
        </NavLink>
        <NavLink
          initial={false}
          animate={isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              ...variants.show,
              transition: { delay: 0.7, duration: 0.2 },
            },
            hide: {
              ...variants.hide,
              transition: { delay: 0.25, duration: 0.05 },
            },
          }}
          onClick={isOpen ? true : false}
        >
          <Link to='/joinus'>join us</Link>
        </NavLink>
      </NavList>
    </NavMenuContainer>
  )
}

export default NavMenu
