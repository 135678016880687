import { motion } from 'framer-motion'
import React from 'react'
import { Carousel } from 'react-bootstrap'
import Video1 from '../videos/Creative-Video.mp4'
import Video2 from '../videos/Digital-Video.mp4'
import Video3 from '../videos/Social-Video.mp4'
import './Main.css'
import Button from '../utils/Button'

const CarouselHome = () => {
  return (
    <Carousel fade>
      <Carousel.Item>
        <div className='video-section'>
          <video autoPlay loop muted>
            <source src={Video1} type='video/mp4'></source>
          </video>
        </div>
        <Carousel.Caption>
          <h1 className='yellow'>Creative</h1>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, ease: 'easeIn', delay: 1 }}
          >
            <h3 className='text-title-1'>
              <span></span>
            </h3>
            <Button
              path='/'
              color='#ffae46'
              text='Learn More'
              fontColor='white'
            />
          </motion.div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className='video-section'>
          <video autoPlay loop muted>
            <source src={Video3} type='video/mp4'></source>
          </video>
        </div>
        <Carousel.Caption>
          <h1 className='pink'>Social</h1>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, ease: 'easeIn', delay: 1 }}
          >
            <h3 className='text-title-2'>
              <span></span>
            </h3>
            <Button
              path='/'
              color='#f62c84'
              text='Learn More'
              fontColor='white'
            />
          </motion.div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className='video-section'>
          <video autoPlay loop muted>
            <source src={Video2} type='video/mp4'></source>
          </video>
        </div>
        <Carousel.Caption>
          <h1 className='blue'>Digital</h1>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, ease: 'easeIn', delay: 1 }}
          >
            <h3 className='text-title-3'>
              <span></span>
            </h3>
            <Button
              path='/'
              color='#0071bc'
              text='Learn More'
              fontColor='white'
            />
          </motion.div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}

export default CarouselHome
