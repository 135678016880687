import React from 'react'
import { Col, Form, FormGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import './Forms.css'
import Button from '../../utils/Button'

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = (data, e) => {
    console.log(watch())
    e.target.reset()
  }
  return (
    <Form className='contact-form pr-5' onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <FormGroup as={Col} controlId='formName'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            {...register('name', { required: true })}
            type='text'
            placeholder='Your Name'
          />
        </FormGroup>
      </Form.Row>
      <Form.Row>
        <FormGroup as={Col} controlId='formName'>
          <Form.Label>Company</Form.Label>
          <Form.Control
            {...register('company', { required: true })}
            type='text'
            placeholder='Your Company'
          />
        </FormGroup>
      </Form.Row>
      <Form.Row>
        <FormGroup as={Col} controlId='formName'>
          <Form.Label>Phone</Form.Label>
          <Form.Control
            {...register('phone', { required: true })}
            type='number'
            placeholder='Your Phone Number'
          />
        </FormGroup>
      </Form.Row>
      <Form.Row>
        <FormGroup as={Col} controlId='formEmail'>
          <Form.Label>Email</Form.Label>
          <Form.Control
            {...register('email', {
              required: true,
              pattern: {
                value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
              },
            })}
            type='text'
            placeholder='Your Email'
          />
        </FormGroup>
      </Form.Row>
      {errors.email && <p>All fields with an asterisk (*) are required</p>}
      <Form.Row>
        <FormGroup as={Col} controlId='formMessage'>
          <Form.Label>Message</Form.Label>
          <Form.Control
            {...register('message', { required: false })}
            as='textarea'
            rows={4}
            placeholder='How can we help you?'
          />
        </FormGroup>
      </Form.Row>
      <span className='d-flex justify-content-center'>
        <Button
          path='/'
          color='#FFAE46'
          text='SEND'
          width='20%'
          fontColor='white'
        />
      </span>
    </Form>
  )
}

export default ContactForm
